import React from 'react';
import SchoolSelector from './SchoolSelector';
import NavLinks from './NavLinks';
import UserSection from './UserSection';

const Sidebar = ({ selectedTab, handleSelectTab, handleLogout, user, activeSchool, authorizedSchools, setActiveSchool }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto">
        <div className="mt-4 mx-6">
          <SchoolSelector
            activeSchool={activeSchool}
            authorizedSchools={authorizedSchools}
            setActiveSchool={setActiveSchool}
          />
        </div>
        <div className="mt-12 mb-6 mx-6 space-y-2">
          <NavLinks
            selectedTab={selectedTab}
            handleSelectTab={handleSelectTab}
            activeSchool={activeSchool}
          />
        </div>
      </div>
      <UserSection
        user={user}
        onLogout={handleLogout}
        handleSelectTab={handleSelectTab}
        selectedTab={selectedTab}
      />
    </div>
  );
};

export default Sidebar;