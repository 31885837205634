import * as Sentry from "@sentry/react";

export const logErrorToConsole = (message, error) => {
  console.error(message);
  console.error("Error details:", {
    name: error.name,
    message: error.message,
    stack: error.stack,
    response: JSON.stringify(error.response),
  });
};

export const logErrorToSentryWithContext = (error, context = {}) => {
  // Don't log AuthenticationErrors
  if (error.name === 'AuthenticationError') {
    return;
  }
  
  Sentry.captureException(error, { 
    extra: context,
    tags: {
      errorType: error.name,
      statusCode: error.response?.status
    }
  });
};