import { Dialog, Button } from '@mui/material';

const ConfirmationModal = ({ 
  open, 
  onClose, 
  title, 
  description, 
  cancelButtonText, 
  actionButtonText, 
  cancelHandler, 
  actionHandler 
}) => {
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '15px' } }}>
      <div className='p-8'>
        <h1 className="text-2xl mb-2"><b>{title}</b></h1>
        <div>
          <p className="mb-6">{description}</p>
        </div>
        <div className="flex flex-row w-full justify-between space-x-4">
          <Button className="flex-grow" variant="outlined" color="primary" onClick={cancelHandler} sx={{ color: 'fortify-green', borderColor: 'fortify-green' }}>
            {cancelButtonText}
          </Button>
          <Button className="flex-grow" variant="contained" color="primary" onClick={actionHandler} sx={{ backgroundColor: 'fortify-green' }}>
            {actionButtonText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
