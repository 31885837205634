import React from 'react';

const HomeStats = ({ statsData, selectedPeriod, onPeriodChange }) => {
  const getCurrentMonthName = () => {
    return new Date().toLocaleString('en-US', { month: 'long', timeZone: 'America/Chicago' });
  };

  const getLastMonthName = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toLocaleString('en-US', { month: 'long', timeZone: 'America/Chicago' });
  };

  const {
    started_applications_in_period,
    started_tuition_financed_in_period,

    confirmed_applications_in_period,
    confirmed_tuition_financed_in_period,

    signed_applications_in_period,
    signed_tuition_financed_in_period,

    all_loans_count,
    all_loans_tuition_financed,
    all_loans_outstanding_balance,

    current_loans_count,
    current_loans_outstanding_balance,

    past_due_loans_count,
    past_due_loans_outstanding_balance,

    defaulted_loans_count,
    defaulted_loans_outstanding_balance
  } = statsData;

  return (
    <div className="h-full overflow-y-auto space-y-8">

      <div>
        <div className="home-page-section-title">Application Volume</div>
        <div className="home-page-stat-card flex flex-col">

          <div className="flex space-x-6 border-b border-[#e1e7e5] mb-6">
            <div className={`home-page-stat-selector ${selectedPeriod === 'all_time' ? 'home-page-stat-selector-active' : 'home-page-stat-selector-inactive'}`} onClick={() => onPeriodChange('all_time')}>
              All-time
            </div>
            <div className={`home-page-stat-selector ${selectedPeriod === 'current_month' ? 'home-page-stat-selector-active' : 'home-page-stat-selector-inactive'}`} onClick={() => onPeriodChange('current_month')}>
              {getCurrentMonthName()}
            </div>
            <div className={`home-page-stat-selector ${selectedPeriod === 'last_month' ? 'home-page-stat-selector-active' : 'home-page-stat-selector-inactive'}`} onClick={() => onPeriodChange('last_month')}>
              {getLastMonthName()}
            </div>
          </div>
        
          <div className="flex stat-row">
            <div className="stat">
              <p className="home-stat-title">Started</p>
              <p className="home-stat-number">{started_applications_in_period}</p>
              <p className="home-stat-caption">${Math.round(parseFloat(started_tuition_financed_in_period)).toLocaleString()} in tuition</p>
            </div>
            <div className="stat">
              <p className="home-stat-title">Certified & Verified</p>
              <p className="home-stat-number">{confirmed_applications_in_period}</p>
              <p className="home-stat-caption">${Math.round(parseFloat(confirmed_tuition_financed_in_period)).toLocaleString()} in tuition</p>
            </div>
            <div className="stat">
              <p className="home-stat-title">Signed</p>
              <p className="home-stat-number">{signed_applications_in_period}</p>
              <p className="home-stat-caption">${Math.round(parseFloat(signed_tuition_financed_in_period)).toLocaleString()} in tuition</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="home-page-section-title">Loan Portfolio</div>

        <div className="home-page-stat-card">
          <div className="flex stat-row">
            <div className="stat">
              <p className="home-stat-title">Active Loans</p>
              <p className="home-stat-number">{all_loans_count}</p>
              <p className="home-stat-caption">all-time</p>
            </div>
            <div className="stat">
              <p className="home-stat-title">Total Tuition Funded</p>
              <p className="home-stat-number">${Math.round(parseFloat(all_loans_tuition_financed)).toLocaleString()}</p>
              <p className="home-stat-caption">all-time</p>
            </div>
            <div className="stat">
              <p className="home-stat-title">Outstanding Balance</p>
              <p className="home-stat-number">${Math.round(parseFloat(all_loans_outstanding_balance)).toLocaleString()}</p>
              <p className="home-stat-caption">all-time</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="home-page-section-title">Performance</div>

        <div className="home-page-stat-card">
          <div className="flex stat-row">
            <div className="stat">
              <p className="home-stat-title">On-Time Loans</p>
              <p className="home-stat-number" style={{ color: '#50D081' }}>{current_loans_count}</p>
              <p className="home-stat-caption">${Math.round(parseFloat(current_loans_outstanding_balance)).toLocaleString()} outstanding</p>
            </div>
            <div className="stat">
              <p className="home-stat-title">Past Due Loans</p>
              <p className="home-stat-number" style={{ color: '#F06264' }}>{past_due_loans_count}</p>
              <p className="home-stat-caption">${Math.round(parseFloat(past_due_loans_outstanding_balance)).toLocaleString()} outstanding</p>
            </div>
            <div className="stat">
              <p className="home-stat-title">Defaulted Loans</p>
              <p className="home-stat-number">{defaulted_loans_count}</p>
              <p className="home-stat-caption">${Math.round(parseFloat(defaulted_loans_outstanding_balance)).toLocaleString()} outstanding</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default HomeStats;
