import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress, Snackbar, Button } from '@mui/material';
import { getLoanDetails } from '../../api/loans';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';
import { formatCurrency, formatDate, formatDateString, formatPercent, formatPhone } from '../../utils/formatters';
import StatusPill from '../shared/StatusPill';
import TransactionTable from '../shared/TransactionTable';
import NotesTab from '../shared/NotesTab';
import LoanApplicationData from '../../models/LoanApplicationData';

const LoanDetails = ({ activeSchool, user }) => {
  const { loanId } = useParams();
  const navigate = useNavigate();
  const [loanDetails, setLoanDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('repayments');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const fetchLoanDetails = async () => {
    if (!loanId || !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(loanId)) {
      navigate('/dashboard');
      return;
    }

    try {
      const data = await getLoanDetails(activeSchool.id, loanId);
      const loanData = new LoanApplicationData(data);
      setLoanDetails(loanData);
    } catch (error) {
      logErrorToConsole("Error fetching loan details", error);
      logErrorToSentryWithContext(error, { schoolId: activeSchool.id, loanId });
      navigate('/dashboard');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLoanDetails();
  }, [loanId, activeSchool.id]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  const getLoanStatus = () => {
    if (loanDetails.loan.latestState?.inForbearance) return { text: 'In Forbearance', stage: 'actionRequired' };
    if (loanDetails.loan.latestState?.daysDelinquent > 0) return { text: 'Past Due', stage: 'error' };
    return { text: 'Current', stage: 'success' };
  };

  const loanStatus = getLoanStatus();

  const tabs = [
    { id: 'repayments', label: 'Repayments' },
    { id: 'loanTerms', label: 'Loan Terms' },
    { id: 'programInformation', label: 'Program Info' },
    { id: 'transactions', label: 'Transactions' },
    { id: 'notes', label: 'Notes' },
  ];

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    setSnackbarMessage('Email address copied to clipboard!');
    setIsSnackbarOpen(true);
  };

  const handleCopyPhone = (phone) => {
    navigator.clipboard.writeText(phone);
    setSnackbarMessage('Phone number copied to clipboard!');
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <div className="loan-details-container">
      <div className="loan-details-header">
        <h1 className="page-title">{`${loanDetails.student.account.firstName} ${loanDetails.student.account.lastName}`}</h1>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4">
            <div className="flex items-center cursor-pointer" onClick={() => handleCopyEmail(loanDetails.student.account.email)}>
              <img src="/applicationFrame/emailIcon.svg" alt="Email" className="w-4 h-4 mr-2" />
              <span className="text-secondary-green text-sm">{loanDetails.student.account.email}</span>
            </div>
            <div className="flex items-center cursor-pointer" onClick={() => handleCopyPhone(loanDetails.student.account.phone)}>
              <img src="/applicationFrame/phoneIcon.svg" alt="Phone" className="w-4 h-4 mr-2" />
              <span className="text-secondary-green text-sm">{formatPhone(loanDetails.student.account.phone)}</span>
            </div>
          </div>
          <StatusPill text={loanStatus.text} stage={loanStatus.stage} />
        </div>
        <div className="circle-status-container">
          <CircleStatus 
            label="Autopay"
            status={loanDetails.loan.latestState?.autopayStatus === "Y"}
            color={loanDetails.loan.latestState?.autopayStatus === "Y" ? "bg-fortify-green" : "bg-red-700"}
          />
          <CircleStatus 
            label="Logged Into Portal"
            status={loanDetails.loan.latestState?.lastLogin !== null}
            color={loanDetails.loan.latestState?.lastLogin !== null ? "bg-fortify-green" : "bg-red-700"}
          />
          <CircleStatus 
            label="In School"
            status={loanDetails.loan.latestState?.currentlyInSchool}
            color={loanDetails.loan.latestState?.currentlyInSchool ? "bg-fortify-green" : "bg-red-700"}
          />
          <CircleStatus 
            label="Past Due"
            status={loanDetails.loan.latestState?.daysDelinquent > 0}
            color={loanDetails.loan.latestState?.daysDelinquent > 0 ? "bg-red-700" : "bg-fortify-green"}
          />
          <CircleStatus 
            label="Loan Counseling"
            status={loanDetails.loan.latestState?.loanCounselingCompletedOn !== null}
            color={loanDetails.loan.latestState?.loanCounselingCompletedOn !== null ? "bg-fortify-green" : "bg-red-700"}
          />
        </div>
      </div>

      <div className="loan-details-tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`loan-details-tab ${
              activeTab === tab.id
                ? 'loan-details-tab-active'
                : 'loan-details-tab-inactive'
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className={`loan-details-content ${
        ['transactions', 'notes'].includes(activeTab)
          ? 'loan-details-content-small'
          : 'loan-details-content-default'
      }`}>
        {activeTab === 'repayments' && <RepaymentsTab loanDetails={loanDetails} />}
        {activeTab === 'loanTerms' && <LoanTermsTab loanDetails={loanDetails} />}
        {activeTab === 'programInformation' && <ProgramInformationTab loanDetails={loanDetails} />}
        {activeTab === 'transactions' && <TransactionsTab loanDetails={loanDetails} />}
        {activeTab === 'notes' && <NotesTab loanDetails={loanDetails} activeSchool={activeSchool} user={user} />}
      </div>

      <Snackbar
        open={isSnackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={2000}
        action={
          <Button color="inherit" size="small" onClick={handleSnackbarClose} style={{ padding: '4px', minWidth: 'unset' }}>
            <img src="/close_white.svg" alt="Close" className="w-5 h-5" />
          </Button>
        }
        ContentProps={{
          style: {
            flexWrap: 'nowrap',
            alignItems: 'center',
            padding: '4px 24px',
            fontSize: '0.875rem',
            fontWeight: 500,
            backgroundColor: '#6E6E6F',
            borderRadius: '0.75rem',
          },
        }}
        style={{
          position: 'fixed',
          top: '40px',
          right: '40px',
          zIndex: 9999,
        }}
      />
    </div>
  );
};

const CircleStatus = ({ label, status, color }) => (
  <div className="circle-status">
    <div className={`circle-status-indicator ${color}`}>
      {status ? 'Y' : 'N'}
    </div>
    <div className="circle-status-label">{label}</div>
  </div>
);

const RepaymentsTab = ({ loanDetails }) => (
  <div className="loan-details-grid">
    <InfoItem label="Monthly Payment" value={formatCurrency(loanDetails.loan.monthlyPayment)} />
    <InfoItem label="Loan Term" value={`${loanDetails.loan.loanTerm} months`} />
    <InfoItem label="First Payment Due Date" value={formatDate(loanDetails.loan.latestState?.firstPaymentDueDate)} />
    <InfoItem label="Earliest Owed Payment" value={formatDate(loanDetails.loan.latestState?.earliestOwedPaymentDate)} />
    <InfoItem label="Total Payments Made" value={formatCurrency(loanDetails.loan.latestState?.principalPaidToDate + loanDetails.loan.latestState?.interestPaidToDate)} />
    <InfoItem label="Past Due Balance" value={formatCurrency(loanDetails.loan.latestState?.pastDueAmount)} />
    <InfoItem label="Outstanding Balance" value={formatCurrency(loanDetails.loan.latestState?.currentPrincipalBalance)} />
    <InfoItem label="Total Tuition Collected" value={formatCurrency(loanDetails.loan.latestState?.schoolTuitionReceived)} />
    <InfoItem label="Total Tuition Disbursed" value={formatCurrency(loanDetails.loan.latestState?.schoolTuitionReceived)} />
  </div>
);

const LoanTermsTab = ({ loanDetails }) => (
  <div className="loan-details-grid">
    <InfoItem label="Tuition Request" value={formatCurrency(loanDetails.loan.tuitionFinanced)} />
    <InfoItem label="Down Payment" value={formatCurrency(loanDetails.loan.requiredDownPayment || 0)} />
    <InfoItem label="Final Borrowing Amount" value={formatCurrency(loanDetails.loan.borrowingAmount)} />
    <InfoItem label="Interest Rate" value={formatPercent(loanDetails.loan.interestRate)} />
    <InfoItem label="Monthly Payment" value={formatCurrency(loanDetails.loan.monthlyPayment)} />
    <InfoItem label="Loan Term" value={`${loanDetails.loan.loanTerm} months`} />
    <InfoItem label="Origination Date" value={formatDateString(loanDetails.loan.originatedOn || loanDetails.loan.originationScheduledFor)} />
    <InfoItem label="First Payment Due Date" value={formatDate(loanDetails.loan.latestState?.firstPaymentDueDate)} />
  </div>
);

const ProgramInformationTab = ({ loanDetails }) => (
  <div className="loan-details-grid">
    <InfoItem label="Program Name" value={loanDetails.program.programName} />
    <div className="loan-details-grid">
      <InfoItem label="Start Date" value={formatDateString(loanDetails.startDate)} />
      <InfoItem label="Graduation Date" value={formatDateString(loanDetails.gradDate)} />
    </div>
  </div>
);

const TransactionsTab = ({ loanDetails }) => (
  <div className="transactions-tab">
    {loanDetails.loan.transactions.length > 0 ? (
      <div className="transactions-table-container">
        <TransactionTable 
          transactionData={[...loanDetails.loan.transactions].sort((a, b) => new Date(b.date) - new Date(a.date))} 
          showStudentInfo={false} 
          enableHoverAndClick={false} 
        />
      </div>
    ) : (
      <div className="transactions-empty-message">
        <p className="text-sm text-legal-gray">There aren't any transactions for {loanDetails.student.account.firstName} yet.</p>
      </div>
    )}
  </div>
);

const InfoItem = ({ label, value }) => (
  <div className="info-item">
    <div className="info-item-label">{label}</div>
    <div>{value}</div>
  </div>
);

export default LoanDetails;
