import React, { useState, useEffect } from 'react';
import { CircularProgress, Alert } from '@mui/material';
import { NOTIFICATION_TYPES, updateNotificationSettings, getNotificationSettings } from '../../../api/notifications';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const NotificationSettings = ({ activeSchool }) => {
  const [subscribedNotifications, setSubscribedNotifications] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    loadNotificationSettings();
  }, [activeSchool]);

  const loadNotificationSettings = async () => {
    try {
      const data = await getNotificationSettings(activeSchool.id);
      setSubscribedNotifications(new Set(data.subscribed_notifications));
    } catch (error) {
      logErrorToConsole("Error fetching notification settings", error);
      logErrorToSentryWithContext(error, {
        schoolId: activeSchool.id,
      });
      setApiError("Failed to load notification settings");
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleNotification = (notificationId) => {
    setSubscribedNotifications(prev => {
      const newSet = new Set(prev);
      if (newSet.has(notificationId)) {
        newSet.delete(notificationId);
      } else {
        newSet.add(notificationId);
      }
      return newSet;
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    setApiError(null);
    setSuccessMessage('');

    try {
      const currentNotifications = Array.from(subscribedNotifications);
      const allNotificationTypes = Object.values(NOTIFICATION_TYPES).map(t => t.id);
      
      await updateNotificationSettings(activeSchool.id, {
        subscribe: currentNotifications,
        unsubscribe: allNotificationTypes.filter(t => !currentNotifications.includes(t))
      });
      
      setSuccessMessage('Your email settings were successfully updated.');
    } catch (error) {
      logErrorToConsole("Error updating notification settings", error);
      logErrorToSentryWithContext(error, {
        schoolId: activeSchool.id,
      });
      setApiError(error?.response?.data?.error || "There was an error updating your notification settings. Our team was notified and we're looking into it.");
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="notification-settings-container">
      <div className="notification-settings-content">
        <h1 className="page-title">Notification Settings</h1>

        <p>Select the emails you'd like to receive from Fortify:</p>
        
        <div className="notification-settings-grid">
          {Object.values(NOTIFICATION_TYPES).map((type) => (
            <div
              key={type.id}
              className={`notification-option ${subscribedNotifications.has(type.id) ? 'selected' : ''}`}
              onClick={() => handleToggleNotification(type.id)}
            >
              <div className="notification-header">
                <input
                  type="checkbox"
                  checked={subscribedNotifications.has(type.id)}
                  onChange={() => {}}
                  className="notification-checkbox"
                />
                <div className="notification-content">
                  <div className="notification-label">{type.label}</div>
                  <div className="notification-description">{type.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {apiError && (
          <Alert severity="error" className="mt-4">
            {apiError}
          </Alert>
        )}

        {successMessage && (
          <Alert severity="success" className="mt-4">
            {successMessage}
          </Alert>
        )}

        <div className="mt-6 flex justify-end">
          <button
            className="save-button flex items-center justify-center gap-2"
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Save Changes'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;