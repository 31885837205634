import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SchoolSelector = ({ activeSchool, authorizedSchools, setActiveSchool }) => {
  const [isSchoolDropdownOpen, setIsSchoolDropdownOpen] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const defaultLogo = "https://fortifyedu.retool.com/api/file/55e28733-092f-4d59-a1b1-f84b89e58753";

  const handleSchoolClick = () => {
    if (authorizedSchools.length > 1) {
      setIsSchoolDropdownOpen(!isSchoolDropdownOpen);
    }
  };

  const shouldResetToDashboard = (pathname) => {
    const privilegedPaths = [
      /^\/loans\/[0-9a-fA-F-]+$/,
    ];

    return privilegedPaths.some(path => {
      if (path instanceof RegExp) {
        return path.test(pathname);
      }
      return pathname === path;
    });
  };

  const handleSchoolSelect = (school) => {
    if (shouldResetToDashboard(location.pathname)) {
      navigate('/dashboard');
    }
    setActiveSchool(school);
    setIsSchoolDropdownOpen(false);
  };

  const handleImageLoad = (schoolId) => {
    setImagesLoaded(prev => ({ ...prev, [schoolId]: true }));
  };

  const renderSchoolLogo = (school) => (
    <>
      <div className={`school-logo-placeholder ${imagesLoaded[school.id] ? 'hidden' : ''}`}></div>
      <img
        src={(school.school_logo || defaultLogo)}
        alt="School Logo"
        className={`school-logo ${imagesLoaded[school.id] ? '' : 'hidden'}`}
        onLoad={() => handleImageLoad(school.id)}
      />
    </>
  );

  return (
    <div className="school-selector">
      <div
        className={`school-selector-content ${authorizedSchools.length > 1 ? 'hoverable' : ''}`}
        onClick={handleSchoolClick}
      >
        {renderSchoolLogo(activeSchool)}
        <div className="school-name">{activeSchool.school_name}</div>
        {authorizedSchools.length > 1 && (
          <img
            src="/sidebarIcons/schoolSelectorArrows.svg"
            alt="Dropdown"
            className="school-selector-arrow"
          />
        )}
      </div>
      {isSchoolDropdownOpen && (
        <div className="school-dropdown">
          {authorizedSchools.filter(school => school.id !== activeSchool.id).map(school => (
            <div
              key={school.id}
              className="school-dropdown-item"
              onClick={() => handleSchoolSelect(school)}
            >
              {renderSchoolLogo(school)}
              <div className="school-name">{school.school_name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SchoolSelector;