import React, { useState } from 'react';
import { TextField, Button, Alert, CircularProgress } from '@mui/material';
import { requestPasswordReset } from '../api/auth';
import FortifyIcon from './FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const RequestPasswordReset = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (emailAddress) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailAddress);
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      await requestPasswordReset(emailAddress);
      setSuccessMessage(true);
    } catch (error) {
      if (error.response?.data?.error === 'Account not found.') {
        setSuccessMessage(true);
      } else {
        logErrorToConsole("Failed to request password reset", error);
        logErrorToSentryWithContext(error, {
          emailAddress,
        });

        setErrorMessage(
          error.response?.data?.error || "There was an error sending you a password reset. We're looking into it!"
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-fortify-green">
      <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-lg w-96">
        <FortifyIcon />
        <h1 className="text-2xl font-bold my-2 mb-8">Reset your password</h1>
        {successMessage ? (
          <Alert severity="success" className="text-center">
            If an account with that email exists, we've sent a password reset link. Please check your email.
          </Alert>
        ) : (
          <>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              id="email"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            {errorMessage && <Alert severity="error" className="mt-4 text-center">{errorMessage}</Alert>}
            <div className="mt-8 w-full text-center">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit}
                  disabled={!isValidEmail(emailAddress)}
                  sx={{ height: '48px' }}
                >
                  Request
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequestPasswordReset;