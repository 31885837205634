import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatDate } from '../../utils/formatters';

const TransactionTable = ({ transactionData, showStudentInfo = true, enableHoverAndClick = true }) => {
  const navigate = useNavigate();

  const handleRowClick = (loanId) => {
    if (enableHoverAndClick) {
      navigate(`/loans/${loanId}`);
    }
  };

  return (
    <table className="loan-table">
      <thead className="sticky top-0 bg-gray-100">
        <tr className="h-12">
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Transaction Date</th>
          {showStudentInfo && (
            <>
              <th className="py-2 px-4 border-b text-left whitespace-nowrap">Student</th>
              <th className="py-2 px-4 border-b text-left whitespace-nowrap">Program</th>
            </>
          )}
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Type</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Amount</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Principal</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Interest</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Late Charges</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Collection/Recovery Fees</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Fortify Platform Fee</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Fortify Portfolio Management Fee</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">School Tuition Received</th>
          <th className="py-2 px-4 border-b text-left whitespace-nowrap">Disbursement Date</th>
        </tr>
      </thead>
      <tbody>
        {transactionData.map((item, index) => (
          <tr 
            key={index} 
            className={`border-b h-12 ${enableHoverAndClick ? 'cursor-pointer hover:bg-gray-100' : ''}`} 
            onClick={() => handleRowClick(item.loanId)}
          >
            <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.date)}</td>
            {showStudentInfo && (
              <>
                <td className="py-2 px-4 whitespace-nowrap">{item.studentName}</td>
                <td className="py-2 px-4 whitespace-nowrap">{item.programName}</td>
              </>
            )}
            <td className="py-2 px-4 whitespace-nowrap">{item.type}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.amount)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.principal)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.interest)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.lateCharges)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.colRcFees)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPlatformFeeReceived)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.fortifyPortfolioManagementFeeReceived)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.schoolTuitionReceived)}</td>
            <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.disbursementDate)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TransactionTable;
