import React, { useState } from 'react';
import { submitFeedback } from '../../api/feedback';

const BugReports = ({user, activeSchool}) => {
  const [feedbackType, setFeedbackType] = useState('bug_report');
  const [issueIdentifier, setIssueIdentifier] = useState('');
  const [details, setDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      await submitFeedback(activeSchool.id, {
        feedback_type: feedbackType,
        issue_identifier: issueIdentifier,
        details: details
      });
      setSuccess(true);
    } catch (err) {
      setError('There was an error submitting your bug report. Isn\'t that ironic? Please do us a favor and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFeedbackType('bug_report');
    setIssueIdentifier('');
    setDetails('');
    setSuccess(false);
    setError(null);
  };

  return (
    <div className="flex flex-col w-full overflow-y-auto h-full">
      <div className="page-title">Bug Reports & Feature Requests</div>
      <hr className='my-2 line-divider'/>
      <div className="flex h-full mt-2">
        <div className="w-4/5 xl:w-3/5 2xl:w-3/5 pr-6 flex flex-col">
          {!success && (
            <p className="w-full xl:w-4/5 text-sm lg:text-base mb-4 text-dark-green">
              Do you see a bug in your Fortify portal? Have a new feature you'd like us to add? Do you think our engineers deserve to have real coffee, instead of the cheap instant stuff we found in the back of our kitchen cabinet?
              <br/><br/>
              Tell us about it!
            </p>
          )}
          {!success ? (
            <form onSubmit={handleSubmit} className="mt-4 space-y-6">
              <div className="form-group">
                <label htmlFor="feedbackType" className="block text-sm font-medium text-gray-700 mb-1">Feedback type</label>
                <select
                  id="feedbackType"
                  value={feedbackType}
                  onChange={(e) => setFeedbackType(e.target.value)}
                  className="form-select w-full"
                >
                  <option value="bug_report">Bug report</option>
                  <option value="feature_request">New feature request</option>
                </select>
              </div>

              {feedbackType === 'bug_report' && (
                <div className="form-group">
                  <label htmlFor="issueIdentifier" className="block text-sm font-medium text-gray-700 mb-1">Issue location</label>
                  <input
                    type="text"
                    id="issueIdentifier"
                    value={issueIdentifier}
                    onChange={(e) => setIssueIdentifier(e.target.value)}
                    className="form-input w-full"
                    placeholder="The portal page you were on"
                    required
                  />
                </div>
              )}

              <div className="form-group">
                <label htmlFor="details" className="block text-sm font-medium text-gray-700 mb-1">Details</label>
                <textarea
                  id="details"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  className="form-textarea w-full"
                  rows="5"
                  placeholder={feedbackType === 'bug_report' ? 
                    "...a specific student's name, which action you were trying to take, or what you expected to see. The more details you include, the faster we can fix the bug for you :)" :
                    "...what new feature you'd like to see or any ideas you have for improving Fortify. Please don't hold back, we'd love to hear your suggestions!"}
                  required
                ></textarea>
              </div>

              <button type="submit" className="submit-button w-full" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit Feedback'}
              </button>

              {error && <div className="error-message mt-4">{error}</div>}
            </form>
          ) : (
            <div className="success-message">
              {feedbackType === 'bug_report' ? (
                <p>Your bug report was submitted. We'll email you at {user.email} as soon as we have updates. Sorry for the inconvenience.</p>
              ) : (
                <p>Thank you for the feature request! We will do our best to get this in as soon as possible for you.</p>
              )}
              <button onClick={resetForm} className="submit-button mt-8 w-1/2 mx-auto rounded-lg">Submit another request</button>
            </div>
          )}
        </div>
        
        <div className="w-3/5 pl-6 flex flex-col">
        </div>
      </div>
    </div>
  );
};

export default BugReports;
