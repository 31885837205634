import axios from '../utils/axiosSetup';

export const fetchNotesForLoan = async (schoolId, loanId) => {
    const response = await axios.get(`schools/${schoolId}/loans/${loanId}/notes`);
    return response.data;
};

export const createNoteForLoan = async (schoolId, loanId, content) => {
    const response = await axios.post(`schools/${schoolId}/loans/${loanId}/notes`, { content });
    return response.data;
};

export const updateNoteForLoan = async (schoolId, loanId, noteId, content) => {
    const response = await axios.patch(`schools/${schoolId}/loans/${loanId}/notes/${noteId}`, { content });
    return response.data;
};

export const deleteNoteForLoan = async (schoolId, loanId, noteId) => {
    const response = await axios.delete(`schools/${schoolId}/loans/${loanId}/notes/${noteId}`);
    return response.data;
};