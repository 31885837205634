import axios from '../utils/axiosSetup';

export const fetchProgramsForSchool = async (schoolId) => {
    const response = await axios.get(`schools/${schoolId}/programs`);
    return response.data;
};

export const calculateExampleTerms = async (schoolId, programId, tuitionRequest, downPayment, studentState, originationDate) => {
    const response = await axios.get(`schools/${schoolId}/${programId}/calculator`, {
      params: {
        tuition_request: tuitionRequest,
        down_payment: downPayment,
        student_state_of_residence: studentState,
        origination_date: originationDate
      }
    });
    return response.data;
  };