import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { handleLogout } from '../utils/auth';
import { CircularProgress } from '@mui/material';

import { AuthenticationError } from '../utils/axiosSetup';
import Sidebar from './portal/sidebar/Sidebar';
import TopBar from './portal/TopBar';

import Dashboard from './portal/home/Dashboard';
import NewApplications from './portal/applications/NewApplications';
import LiveStates from './portal/LiveStates';
import PaymentEstimator from './portal/PaymentEstimator';

import LoanDetails from './portal/LoanDetails';
import UpcomingPayments from './portal/UpcomingPayments';
import LoanPortfolio from './portal/LoanPortfolio';
import PastDue from './portal/PastDue';
import Forbearance from './portal/Forbearance';

import DownPayments from './portal/DownPayments';
import TransactionHistory from './portal/TransactionHistory';
import Disbursements from './portal/Disbursements';

import Contact from './portal/Contact';
import Settings from './portal/settings/Settings';
import BugReports from './portal/BugReports';


import ApplicationFrame from './portal/applicationFrame/ApplicationFrame';
import ConfirmationModal from '../components/ConfirmationModal';

import { getAuthorizedSchools } from '../api/auth';
import SchoolData from '../models/SchoolData';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const Portal = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isInitialized, setIsInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const [authorizedSchools, setAuthorizedSchools] = useState(null);
  const [activeSchool, setActiveSchool] = useState(null);
  const [refreshDataTrigger, setRefreshDataTrigger] = useState(0);

  const [isApplicationFrameOpen, setIsApplicationFrameOpen] = useState(false);
  const [applicationFrameData, setApplicationFrameData] = useState(null);

  const [selectedTab, setSelectedTab] = useState('dashboard');
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!accessToken || !refreshToken) {
      navigate('/login', { replace: true });
      return;
    }

    if (!isInitialized) {
      initializePortal();
    }
  }, [isInitialized]);

  const initializePortal = async () => {
    try {
      const response = await getAuthorizedSchools();
      const authorizedSchools = response.schools.map(s => new SchoolData(s));
      
      setUser({
        name: response.name,
        firstName: response.first_name,
        lastName: response.last_name,
        email: response.email,
        phone: response.phone,
      });
      
      setAuthorizedSchools(authorizedSchools);
      
      if (!activeSchool) {
        setActiveSchool(authorizedSchools[0]);
      }
      
      setIsInitialized(true);
    } catch (error) {
      if (error instanceof AuthenticationError) {
        navigate('/login', { replace: true });
      } else {
        logErrorToConsole("Error fetching authorized schools", error);
        logErrorToSentryWithContext(error);
      }
    }
  };

  useEffect(() => {
    const path = location.pathname.split('/')[1];

    const validPaths = [
      'dashboard',
      'all-applications',
      'live-states',
      'payment-estimator',
      'upcoming-payments',
      'loan-portfolio',
      'past-due',
      'forbearance',
      'down-payments',
      'transaction-history',
      'disbursements',
      'contact',
      'settings',
      'bug-reports',
      'loans',
    ];

    if (validPaths.includes(path)) {
      setSelectedTab(path);
    } else {
      navigate('/dashboard');
    }
  }, [location, navigate]);

  const handleSelectTab = (tab) => {
    navigate(`/${tab}`);
  };

  const openApplicationFrame = (applicationData) => {
    setIsApplicationFrameOpen(true);
    setApplicationFrameData(applicationData);
  }

  const handleCloseApplicationFrame = () => {
    setIsApplicationFrameOpen(false);
  };

  const triggerDataRefreshFromApplicationFrame = () => {
    setRefreshDataTrigger(prev => prev + 1);
    setIsApplicationFrameOpen(false);
  };

  const triggerOpenLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };

  const triggerCloseLogoutModal = () => {
    setIsLogoutModalOpen(false);
  };

  if (!isInitialized) {
    return <div className="flex items-center justify-center h-screen">
      <CircularProgress size={40}/>
    </div>;
  }

  return (
    <div id="portal" className="flex h-screen overflow-hidden">
      <div className="sidebar">
        <Sidebar
          selectedTab={selectedTab}
          handleSelectTab={handleSelectTab}
          handleLogout={triggerOpenLogoutModal}
          user={user}
          authorizedSchools={authorizedSchools}
          activeSchool={activeSchool}
          setActiveSchool={setActiveSchool}
        />
      </div>

      <div onClick={handleCloseApplicationFrame} className={`main-content ${isApplicationFrameOpen ? 'opacity-50' : ''}`} >
        <TopBar
          activeSchool={activeSchool}
          selectedTab={selectedTab}
          onSelectApplication={openApplicationFrame}
        />

        <div id="data" className='flex-grow overflow-hidden'>
            <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard activeSchool={activeSchool} firstName={user.firstName} refreshDataTrigger={refreshDataTrigger} />} />
            <Route path="/all-applications" element={<NewApplications activeSchool={activeSchool} isApplicationFrameOpen={isApplicationFrameOpen} onSelectApplication={openApplicationFrame} refreshDataTrigger={refreshDataTrigger} />} />
            <Route path="/live-states" element={<LiveStates />} />
            <Route path="/payment-estimator" element={<PaymentEstimator activeSchool={activeSchool} />} />

            <Route path="/loans/:loanId" element={<LoanDetails activeSchool={activeSchool} user={user} />} />
            <Route path="/upcoming-payments" element={<UpcomingPayments activeSchool={activeSchool} />} />
            <Route path="/loan-portfolio" element={<LoanPortfolio activeSchool={activeSchool} />} />
            <Route path="/past-due" element={<PastDue activeSchool={activeSchool} />} />
            <Route path="/forbearance" element={<Forbearance activeSchool={activeSchool} />} />

            <Route path="/down-payments" element={<DownPayments activeSchool={activeSchool} />} />
            <Route path="/transaction-history" element={<TransactionHistory activeSchool={activeSchool} />} />
            <Route path="/disbursements" element={<Disbursements activeSchool={activeSchool} />} />

            <Route path="/contact" element={<Contact user={user} activeSchool={activeSchool} />} />
            <Route path="/settings/*" element={<Settings user={user} activeSchool={activeSchool} />} />
            <Route path="/bug-reports" element={<BugReports user={user} activeSchool={activeSchool} />} />
            </Routes>
        </div>
      </div>

      <ApplicationFrame
        isOpen={isApplicationFrameOpen}
        applicationData={applicationFrameData}
        onClose={handleCloseApplicationFrame}
        refreshPortalDataOnAction={triggerDataRefreshFromApplicationFrame}
        user={user}
        activeSchool={activeSchool}
      />

      <ConfirmationModal
        open={isLogoutModalOpen}
        onClose={triggerCloseLogoutModal}
        title="Logout"
        description="Are you sure you'd like to log out of Fortify?"
        cancelButtonText="Cancel"
        actionButtonText="Logout"
        cancelHandler={triggerCloseLogoutModal}
        actionHandler={handleLogout}
      />
    </div>
  );
};

export default Portal;

