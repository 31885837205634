import React, { useState } from 'react';

const ExportToCSV = ({ exportToCSV }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={exportToCSV}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="export-csv-button hover:text-hover-green text-fortify-green font-semibold py-2 px-4 rounded flex items-center"
    >
      Export CSV
      <img
        src={isHovered ? "/general/export_to_csv_hover.svg" : "/general/export_to_csv.svg"}
        alt="Download CSV"
        className="w-5 h-5 ml-2"
      />
    </button>
  );
};

export default ExportToCSV;