import React, { useState } from 'react';
import { Snackbar, Button } from '@mui/material';
import { completionStatusBox, certificationStatusBox, verificationStatusBox, downPaymentStatusBox, signatureStatusBox } from './stages';
import ArchiveModal from '../actions/ArchiveModal';
import UnarchiveModal from '../actions/UnarchiveModal';
import CertificationModal from '../actions/CertificationModal';
import ResetCertificationModal from '../actions/ResetCertificationModal';
import NotesTab from '../../shared/NotesTab';

const ApplicationFrame = ({ isOpen, applicationData, onClose, refreshPortalDataOnAction, user, activeSchool }) => {
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isUnarchiveModalOpen, setIsUnarchiveModalOpen] = useState(false);
  const [isCertificationModalOpen, setIsCertificationModalOpen] = useState(false);
  const [isResetCertificationModalOpen, setIsResetCertificationModalOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  if (!isOpen) return null;

  const {
    student: { account: { firstName, lastName, email, phone }, markedIneligibleAt },
    stageSummary,
    createdAt,
    startDate,
    gradDate,
    schoolCertification,
    program: { programName },
    loan: { requiredDownPayment, downPaymentReceivedOn, executedOn },
    archivedAt,
    tuitionRequestStatus
  } = applicationData;

  console.log(tuitionRequestStatus);

  const requiredDownPaymentFloat = (!requiredDownPayment ? 0 : parseFloat(requiredDownPayment))

  const shouldShowArchiveAction = !archivedAt && !executedOn && !markedIneligibleAt;
  const shouldShowUnarchiveAction = !!archivedAt;
  const shouldShowCertifyAction = !archivedAt && tuitionRequestStatus === 'pending_school_approval';
  const shouldShowResetCertificationAction = !archivedAt && !downPaymentReceivedOn && !executedOn && schoolCertification === 'approved' && tuitionRequestStatus !== 'pending_school_approval';

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    setSnackbarMessage('Email address copied to clipboard!');
    setIsSnackbarOpen(true);
  };

  const handleCopyPhone = (phone) => {
    navigator.clipboard.writeText(phone);
    setSnackbarMessage('Phone number copied to clipboard!');
    setIsSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleArchive = () => {
    setIsArchiveModalOpen(true);
  };

  const handleUnarchive = () => {
    setIsUnarchiveModalOpen(true);
  }

  const handleCertify = () => {
    setIsCertificationModalOpen(true);
  };

  const handleResetCertification = () => {
    setIsResetCertificationModalOpen(true);
  }

  const formatCurrency = (amount) => {
    if (amount == null) return "";
    return `$${parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };

  return (
    <div className="right-frame-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-end pointer-events-none">
      <div className="right-frame bg-white w-1/2 xl:w-2/5 2xl:w-1/3 h-screen flex flex-col pointer-events-auto rounded-tl-[30px] rounded-bl-[30px]">
        <div className="px-8 pt-8">
          <div className="flex justify-between items-start mb-2">
            <div className="text-[26px] font-bold text-dark-green font-manrope">{`${firstName} ${lastName}`}</div>
            <button onClick={onClose} className="close-button">
              <img src="/applicationFrame/closeApplicationFrame.svg" alt="Close" />
            </button>
          </div>

          <div className="text-[16px] text-dark-green mt-8 mb-3">{`${firstName}${stageSummary}`}</div>

          <div className="flex flex-row items-start w-full space-x-12 text-gray-500 mb-8">
            <div className="flex items-center cursor-pointer" onClick={() => handleCopyEmail(email)}>
              <img src="/applicationFrame/emailIcon.svg" alt="Email" className="mr-2" />
              <p className="text-secondary-green text-[14px] font-light">{email}</p>
            </div>

            <div className="flex items-center cursor-pointer" onClick={() => handleCopyPhone(phone)}>
              <img src="/applicationFrame/phoneIcon.svg" alt="Phone" className="mr-2" />
              <p className="text-secondary-green text-[14px] font-light">{`(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`}</p>
            </div>
          </div>

          <hr className="my-8 line-divider" />

        </div>

        {/* Scrollable content */}
        <div className="flex-1 overflow-y-auto px-8 pb-8">
          {(shouldShowArchiveAction || shouldShowUnarchiveAction || shouldShowCertifyAction || shouldShowResetCertificationAction) && (
            <>
              <h2 className="application-frame-section-title">Actions</h2>
              <div className="flex flex-col w-full space-y-3">
                {shouldShowUnarchiveAction && (
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ backgroundColor: '#00684a', color: 'white', borderRadius: '8px', height: '44px', textTransform: 'none', fontSize: '15px' }}
                    onClick={handleUnarchive}
                  >
                    Unarchive
                  </Button>
                )}
                <div className="flex flex-row space-x-3">
                  {shouldShowArchiveAction && (
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ boxShadow: 'none', backgroundColor: '#dbeae5', color: '#00684a', borderRadius: '8px', height: '44px', textTransform: 'none', fontSize: '15px' }}
                      onClick={handleArchive}
                    >
                      Archive
                    </Button>
                  )}
                  {shouldShowCertifyAction && (
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ boxShadow: 'none', backgroundColor: '#00684a', color: 'white', borderRadius: '8px', height: '44px', textTransform: 'none', fontSize: '15px' }}
                      onClick={handleCertify}
                    >
                      Certify
                    </Button>
                  )}
                  {shouldShowResetCertificationAction && (
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ boxShadow: 'none', backgroundColor: '#da4244', color: 'white', borderRadius: '8px', height: '44px', textTransform: 'none', fontSize: '15px' }}
                      onClick={handleResetCertification}
                    >
                      Reset to certification
                    </Button>
                  )}
                </div>
              </div>
              <hr className="my-8 line-divider" />
            </>
          )}

          <h2 className="application-frame-section-title">Application Stage</h2>

          {/* Ah, Javascript. createdAt is a timestamp, so it's read correctly. Print it in America/Chicago, not UTC. */}
          <div className="mb-4 text-[14px] text-dark-green">Application started on: {new Date(createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'America/Chicago' })}</div>
          <div className="flex flex-col w-full space-y-2">
            {completionStatusBox(applicationData)}
            {certificationStatusBox(applicationData)}
            {verificationStatusBox(applicationData)}
            {requiredDownPaymentFloat > 0 && downPaymentStatusBox(applicationData, requiredDownPaymentFloat)}
            {signatureStatusBox(applicationData)}
          </div>

          <hr className="my-8 line-divider" />

          {/* Program Info */}
          <div>
            <h2 className="application-frame-section-title">Program Info</h2>
            <div className="grid grid-cols-2 gap-2">
              <div className="col-span-2 mb-2 application-frame-body"><span className="font-bold">Program:</span> {programName}</div>
              <div className='application-frame-body'><span className="font-bold">Start date:</span> {new Date(startDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })}</div>
              <div className='application-frame-body'><span className="font-bold">Graduation date:</span> {new Date(gradDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })}</div>
            </div>
          </div>

          <hr className="my-8 line-divider" />

          {/* Loan Terms */}
          {applicationData.schoolCertification === "approved" && (
            <>
              <div>
                <h2 className="application-frame-section-title">Loan Info</h2>
                <div className="grid grid-cols-2 gap-2">
                  <div className="application-frame-body mb-2"><span className="font-bold">Down Payment:</span> {formatCurrency(applicationData.loan.requiredDownPayment || 0)}</div>
                  <div className="application-frame-body mb-2"><span className="font-bold">Tuition Financed:</span> {formatCurrency(applicationData.loan.tuitionFinanced)}</div>
                  <div className="application-frame-body mb-2"><span className="font-bold">Origination Fee:</span> {formatCurrency(applicationData.loan.borrowingAmount - applicationData.loan.tuitionFinanced)}</div>
                  <div className="application-frame-body mb-2"><span className="font-bold">Borrowing Amount:</span> {formatCurrency(applicationData.loan.borrowingAmount)}</div>

                </div>

                <hr className="my-8 line-divider" />

                <h2 className="application-frame-section-title">Repayment Terms</h2>
                <div className="grid grid-cols-2 gap-2">
                  <div className="application-frame-body mb-2"><span className="font-bold">Loan Term:</span> {applicationData.loan.loanTerm} months</div>
                  <div className="application-frame-body mb-2"><span className="font-bold">Monthly Payment:</span> {formatCurrency(applicationData.loan.monthlyPayment)}</div>
                  <div className="application-frame-body mb-2"><span className="font-bold">Interest Rate:</span> {(applicationData.loan.interestRate * 100).toFixed(2)}%</div>
                  <div className="application-frame-body mb-2"><span className="font-bold">First Payment Date:</span> {new Date(applicationData.loan.firstPaymentDueDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' })}</div>
                </div>
              </div>

              <hr className="my-8 line-divider" />
            </>
          )}

          <hr className="my-8 line-divider" />
          
          <h2 className="application-frame-section-title">Notes</h2>
          <NotesTab 
            loanDetails={applicationData} 
            activeSchool={activeSchool}
            user={user}
          />
        </div>

        {shouldShowArchiveAction && <ArchiveModal
          open={isArchiveModalOpen}
          onClose={() => setIsArchiveModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}

        {shouldShowUnarchiveAction && <UnarchiveModal
          open={isUnarchiveModalOpen}
          onClose={() => setIsUnarchiveModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}

        {shouldShowResetCertificationAction && <ResetCertificationModal
          open={isResetCertificationModalOpen}
          onClose={() => setIsResetCertificationModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}

        {shouldShowCertifyAction && <CertificationModal
          open={isCertificationModalOpen}
          onClose={() => setIsCertificationModalOpen(false)}
          applicationData={applicationData}
          onActionSuccess={refreshPortalDataOnAction}
        />}
      </div>

      <Snackbar
          open={isSnackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={2000}
          action={
            <Button color="inherit" size="small" onClick={handleSnackbarClose} style={{ padding: '4px', minWidth: 'unset' }}>
              <img src="/close_white.svg" alt="Close" className="w-5 h-5" />
            </Button>
          }
          ContentProps={{
            style: {
              flexWrap: 'nowrap',
              alignItems: 'center',
              padding: '4px 24px',
              fontSize: '0.875rem',
              fontWeight: 500,
              backgroundColor: '#6E6E6F',
              borderRadius: '0.75rem',
            },
          }}
          style={{
            position: 'fixed',
            top: '40px',
            right: '40px',
            zIndex: 9999,
          }}
        />

    </div>
  );
};

export default ApplicationFrame;