import React, { useState, useEffect } from 'react';
import HomeStats from './HomeStats';
import ActionItems from './ActionItems';
import LoanApplicationData from '../../../models/LoanApplicationData';
import { CircularProgress } from '@mui/material';
import { fetchHomepageForSchool } from '../../../api/applications';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

const Dashboard = ({ activeSchool, firstName, refreshDataTrigger }) => {
  const [homepageData, setHomepageData] = useState(null);
  const [homepageDataIsLoading, setHomepageDataIsLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('all_time');

  const getPeriodDates = () => {
    const centralTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }));
    const nextDay = new Date(centralTime);
    nextDay.setTime(nextDay.getTime() + 24 * 60 * 60 * 1000);

    const formatDate = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const nextDayFormatted = formatDate(nextDay);
    const currentYear = centralTime.getFullYear();
    const currentMonth = centralTime.getMonth();
    
    switch (selectedPeriod) {
      case 'all_time':
        return `2024-06-01_${nextDayFormatted}`;
      case 'current_month':
        return `${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-01_${currentYear}-${(currentMonth + 2).toString().padStart(2, '0')}-01`;
      case 'last_month':
        return `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01_${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}-01`;
      default:
        return `2024-06-01_${nextDayFormatted}`;
    }
  };

  const handlePeriodChange = (newPeriod) => {
    setSelectedPeriod(newPeriod);
  };

  const fetchHomepageData = async () => {
    if (activeSchool && activeSchool.id) {
      setHomepageDataIsLoading(true);
      try {
        const period = getPeriodDates();
        const data = await fetchHomepageForSchool(activeSchool.id, period);
        setHomepageData(data);
      } catch (error) {
        logErrorToConsole("Error fetching homepage data", error);
        logErrorToSentryWithContext(error, {
          schoolId: activeSchool.id,
        });
      } finally {
        setHomepageDataIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchHomepageData();
  }, [activeSchool, refreshDataTrigger, selectedPeriod]);

  const { 
    stats: statsData,
    students_to_certify: studentsToCertify
  } = homepageData || {};

  const loanApplications = studentsToCertify ? studentsToCertify.map(result => new LoanApplicationData(result)) : [];
  const sortedLoanApplications = loanApplications.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

  if (homepageDataIsLoading || statsData === undefined || studentsToCertify === undefined) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="home-stats-container">
          <HomeStats statsData={statsData} selectedPeriod={selectedPeriod} onPeriodChange={handlePeriodChange} />
        </div>
        <div className="action-items-container">
          <ActionItems applicationsToCertify={sortedLoanApplications} onActionSuccess={fetchHomepageData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;