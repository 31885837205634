import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavLinks = ({ selectedTab, handleSelectTab, activeSchool }) => {
  const [hoveredTab, setHoveredTab] = useState(null);

  const getIcon = (tab) => {
    const isActive = selectedTab === tab.replace(/\s+/g,'-').toLowerCase();
    const isHovered = hoveredTab === tab;
    if (isActive) {
      return `/sidebarIcons/${tab.replace(/\s+/g,'-').toLowerCase()}.svg`;
    }
    return isHovered
      ? `/sidebarIcons/${tab.replace(/\s+/g,'-').toLowerCase()}_hover.svg`
      : `/sidebarIcons/${tab.replace(/\s+/g,'-').toLowerCase()}.svg`;
  };

  const renderTab = (tab) => {
    const isActive = selectedTab === tab.replace(/\s+/g, '-').toLowerCase();
    const isHovered = hoveredTab === tab;

    return (
      <div
        key={tab}
        className={`mt-1 sidebar-link ${
          isActive
            ? 'sidebar-link-active'
            : `sidebar-link-inactive ${isHovered ? 'text-sidebar-hover-green' : ''}`
        }`}
        onClick={() => handleSelectTab(tab.replace(/\s+/g, '-').toLowerCase())}
        onMouseEnter={() => setHoveredTab(tab)}
        onMouseLeave={() => setHoveredTab(null)}
      >
        <Link to={`/${tab.toLowerCase()}`} className="no-underline flex items-center">
          <img src={getIcon(tab)} alt={`${tab} icon`} className="page-icon mr-2.5" />
          {tab}
        </Link>
      </div>
    );
  };

  const renderSection = (section) => (
    <div key={section.section} className="mt-8">
      <div className="sidebar-section-heading mb-3">{section.section}</div>
      {section.tabs.map(renderTab)}
    </div>
  );

  const adminSidebarStructure = [
    { tab: 'Dashboard', section: null },
    {
      section: 'New Applications',
      tabs: ['All Applications', 'Live States', 'Payment Estimator']
    },
    {
      section: 'Repayments',
      tabs: ['Upcoming Payments', 'Loan Portfolio', 'Past Due', 'Forbearance']
    },
    {
      section: 'Accounting',
      tabs: ['Down Payments', 'Transaction History', 'Disbursements']
    }
  ];

  const repSidebarStructure = [
    { tab: 'Dashboard', section: null },
    {
      section: 'New Applications',
      tabs: ['All Applications', 'Live States', 'Payment Estimator']
    },
  ];

  const renderSidebar = () => {
    const sidebarStructure = (activeSchool.is_admin ? adminSidebarStructure : repSidebarStructure)

    const filteredSidebarStructure = sidebarStructure.map(item => {
        if (item.section === 'Accounting' && !activeSchool.is_collecting_down_payment_with_fortify) {
            return {
                ...item,
                tabs: item.tabs.filter(tab => tab !== 'Down Payments')
            };
        }
        return item;
    });

    return filteredSidebarStructure.map(item => 
        item.section ? renderSection(item) : renderTab(item.tab)
    );
  };

  return (
    <div>
      {renderSidebar()}
    </div>
  );
};

export default NavLinks;