import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.css';
import AppWrapper from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://506af29f916ddc5ba0a69b96f9cd2855@o4507132225191936.ingest.us.sentry.io/4507502794309632",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  environment: process.env.REACT_APP_APP_ENV === "dev" ? "dev" : "prod",
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/api\.fortifyedu\.com/],
  replaysSessionSampleRate: process.env.REACT_APP_APP_ENV === "dev" ? 0 : 0.50,
  replaysOnErrorSampleRate: 1.0,
});

export const enableSentryReplayIntegrationIfNotEnabled = () => {
  const client = Sentry.getClient();

  if (!client["_integrations"]["Replay"]) {
    Sentry.addIntegration(Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }));
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppWrapper />
);

reportWebVitals();
