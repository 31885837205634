import React from 'react';

const PillButton = ({ text, isActive, onClick, count, category }) => {
  const getCircleColor = () => {
    if (isActive) return 'bg-pink';
    if (category === 'schoolCertification') return count > 0 ? 'bg-[#D29700]' : 'bg-[#D29700] bg-opacity-50';
    return count > 0 ? 'bg-fortify-green bg-opacity-45' : 'bg-fortify-green bg-opacity-20';
  };

  const getCircleTextColor = () => {
    if (isActive) return 'text-white';
    if (category === 'schoolCertification') return 'text-white';
    return 'text-dark-green';
  };

  return (
    <button
      className={`pill-button ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <span className="pill-text">{text}</span>
      <span className={`pill-circle ${getCircleColor()} ${getCircleTextColor()}`}>
        {count}
      </span>
    </button>
  );
};

export default PillButton;