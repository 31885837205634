import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountSettings from './AccountSettings';
import MemberSettings from './MemberSettings';
import SchoolSettings from './SchoolSettings';
import ProgramSettings from './ProgramSettings';
import NotificationSettings from './NotificationSettings';

const Settings = ({ user, activeSchool }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedSettingsTab, setSelectedSettingsTab] = useState('team members');

  useEffect(() => {
    const path = location.pathname.split('/settings/')[1];
    if (path) {
      const validTabs = ['team-members', 'live-programs', 'school-info', 'notifications'];
      if (validTabs.includes(path)) {
        setSelectedSettingsTab(path.replace('-', ' '));
      } else {
        navigate('/settings/team-members', { replace: true });
      }
    } else {
      navigate('/settings/team-members', { replace: true });
    }
  }, [location, navigate]);

  const handleSelectSettingsTab = (tab) => {
    setSelectedSettingsTab(tab);
    navigate(`/settings/${tab.replace(' ', '-')}`);
  };

  return (
    <div className="flex h-full overflow-hidden">
      <div className="h-full">
        <div className="h-full flex flex-col xl:ml-3 2xl:ml-6 border-r border-gray-300 pr-6">
          {['Team Members', 'Live Programs', 'School Info', 'Notifications'].map((tab) => (
            <div
              key={tab}
              className={`min-w-[125px] xl:min-w-[150px] 2xl:min-w-[175px] cursor-pointer py-2 px-4 ${
                selectedSettingsTab === tab.toLowerCase() ? 'text-black font-bold' : 'text-gray-500'
              }`}
              onClick={() => handleSelectSettingsTab(tab.toLowerCase())}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>

      <div className="flex-grow px-6 overflow-hidden">
        {selectedSettingsTab === 'account' && <AccountSettings user={user} />}
        {selectedSettingsTab === 'team members' && <MemberSettings user={user} activeSchool={activeSchool} />}
        {selectedSettingsTab === 'school info' && <SchoolSettings activeSchool={activeSchool} />}
        {selectedSettingsTab === 'live programs' && <ProgramSettings activeSchool={activeSchool} />}
        {selectedSettingsTab === 'notifications' && <NotificationSettings activeSchool={activeSchool} />}
      </div>
    </div>
  );
};

export default Settings;