import React from 'react';

const stageConfigs = {
  success: {
    icon: "/applicationFrame/stageSuccess.svg",
    backgroundColor: "#F2F7F6",
    textColor: "text-[#00684A]",
    fontWeight: "font-semibold"
  },
  actionRequired: {
    icon: "/applicationFrame/stageActionRequired.svg",
    backgroundColor: "#FFF8E2",
    textColor: "text-[#F9AA00]",
    fontWeight: "font-semibold"
  },
  incomplete: {
    icon: "/applicationFrame/stageIncomplete.svg",
    backgroundColor: "rgba(151, 165, 162, 0.04)",
    textColor: "text-[#97A5A2]",
    fontWeight: "font-normal"
  },
  error: {
    icon: "/applicationFrame/stageError.svg",
    backgroundColor: "rgba(218, 66, 68, 0.12)",
    textColor: "text-[#DA4244]",
    fontWeight: "font-semibold"
  },
  archived: {
    icon: "/applicationFrame/stageArchived.svg",
    backgroundColor: "rgba(151, 165, 162, 0.32)",
    textColor: "text-[#5D5D5D]",
    fontWeight: "font-medium"
  }
};

const StageBox = ({ stage, text }) => {
  const config = stageConfigs[stage];
  const borderStyle = stage !== 'incomplete' ? 'border-l-2' : '';

  return (
    <div 
      className={`stage-box rounded-lg ${borderStyle}`} 
      style={{ 
        backgroundColor: config.backgroundColor,
        borderLeftColor: (stage !== 'incomplete') ? (config.textColor.replace('text-', '').replace('[', '').replace(']', '')) : 'transparent'
      }}
    >
      <div className="box flex items-center p-2 rounded-[8px]">
        <img src={config.icon} alt="Stage Icon" className="w-5 h-5 ml-1 mr-3" />
        <div className={`text-[16px] text-[1.08rem] ${config.textColor} ${config.fontWeight}`}>{text}</div>
      </div>
    </div>
  );
};

export default StageBox;
