import React from 'react';
import CertificationAction from './CertificationAction';

const ActionItems = ({ applicationsToCertify, onActionSuccess }) => {
  return (
    <div className="action-items">
      <div className="home-page-section-title">Pending actions</div>
      <div className="action-items-content">
        {applicationsToCertify.length === 0 ? (
          <div className="action-items-empty">
            <div className="action-items-empty-message">Nothing to do right now - you're all caught up!</div>
          </div>
        ) : (
          <div className="action-items-list">
            {applicationsToCertify.map(application => (
              <CertificationAction key={application.id} application={application} onActionSuccess={onActionSuccess} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionItems;